"use client";
import React, { useEffect } from "react";
import { logClientError } from "../hooks/log-client-error";
import { ErrorArtWork } from "./error-art-work";

export type ErrorPageProps = {
  refreshUrl?: string;
  messages: {
    tryANewSearch: string;
    info: string;
  };
  error: Error & { digest?: string };
  reset: () => void;
};

export const ErrorPage = ({ error, messages, reset }: ErrorPageProps) => {
  useEffect(() => {
    if (error) {
      logClientError(error);
    }
  }, [error]);

  return (
    <div className="h-full w-full bg-white p-8">
      <div className="flex h-[80%] flex-col items-center justify-center gap-4 md:gap-6 lg:gap-8">
        {/* <ErrorMessageArtwork width="100%" sx={svgSize} /> */}
        <ErrorArtWork />
        <div className="text-14 sm:text-16 flex items-center gap-2">
          <h2>{messages.info}</h2>
          <button
            className="text-content text-nowrap bg-primary p-2"
            onClick={
              // Attempt to recover by trying to re-render the segment
              () => reset()
            }
          >
            {messages.tryANewSearch}
          </button>
        </div>
      </div>
    </div>
  );
};
